import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import i18n from '@/libs/i18n'
import { ref } from '@vue/composition-api'
import realmConnection from '@/views/habit/realm'

export default function useConfirmationPlanningAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { createItems, ObjectId } = realmConnection()
  const userData = store.state?.userStore?.userData
  const isSubmitting = ref(false)
  const confirmationPlanning = ref({})
  const collection = 'confirmation_planning'

  const create = async (data) => {
    isSubmitting.value = true

    const client_id = ObjectId(userData.client.$oid)
    const confirmationsPerYear = Number(data.confirmationsPerYear.value)
    const payload = []

    data.role.value.forEach(r => {
      data.process.value.forEach(p => {
        payload.push({
          client_id,
          role: ObjectId(r),
          process: ObjectId(p),
          confirmationsPerYear
        })
      })
    })

    try {
      await createItems({ collection, payload })
      showSuccessMessage(i18n.t('message.confirmation_planning_added'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.confirmation_planning_add_error'))
    } finally {
      router.push({ name: 'organization-confirmation-planning-list' })
      isSubmitting.value = false
    }
  }

  return {
    create,
    isSubmitting,
    confirmationPlanning,
  }
}
