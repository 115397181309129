<template lang="pug">
div
  Form(:data="confirmationPlanning", :isSubmitting="isSubmitting", :isSelectMultiple="true", @submit="create")
</template>

<script>
import Form from '../shared/Form.vue'
import useConfirmationPlanningAdd from './useConfirmationPlanningAdd'

export default {
  components: {
    Form,
  },
  setup() {
    const { create, isSubmitting, confirmationPlanning } = useConfirmationPlanningAdd()

    return {
      create,
      isSubmitting,
      confirmationPlanning,
    }
  },
}
</script>
